.uk-text-bold { 
  font-weight: 500;
}

.green {
  color: $text-meta-link-color !important;
}

a.green:hover {
  color: inherit !important;
}


.white {
  color: #fff;
}


a,
a * {
  transition: all 0.4s ease-in-out;
}
a:hover,
a:hover * {
  transition: all 0.4s ease-in-out;
}

a {
  &:hover {
    h3 {
      color: $base-link-hover-color;
    }
  }
}

h1 {
  margin-bottom: 40px;
}

@media (min-width: 960px) {
  h1, .uk-h1 {
      font-size: 2.0rem;
  }
}

h1,
h2 {
  margin-bottom: 40px;
}

.tags {
  a {
    font-style: italic;
    font-weight: 500;
  }
}


.uk-text-meta {
  font-weight: 400;
  color: inherit;
  .author {
    font-weight: 500;
  }
}

.b {
  font-weight: 500;
}

.uk-text-italic {
  font-style: italic;
}

.uk-text-truncate-3l {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: $base-body-line-height;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: $base-body-line-height * 3;
  /* fix problem when last visible word doesn't adjoin right side  */
  //text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.uk-text-truncate-3l:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.uk-text-truncate-3l:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}
