.project-index {
    .header {

    }

    .ais-InfiniteHits-item {
        margin-bottom: 90px;
        .uk-card {
            border-left: 10px solid #2b9646;
        }
    }
    .project-search {
        background: #fff;

        border: 1px solid #c4c8d8;
        border-radius: 5px;
        .ais-SearchBox-input {
            border: 0;
            outline-color: transparent !important;
        }
        .uk-button {
            color: #000;
            min-width: 200px;
        }
        .ais-RefinementList-item {
            text-align: left;
        }
    }
}


.project-show {
    .header {
        margin-top: -120px;
    }
    .share {
        background: #fff;
        padding-top: 50px;
        &> div {
            width: 120px;
            &> span {
                color: #1c1814;
                font-size: 12px;
                font-style: italic;
                margin-bottom: 15px;
            }
        }
        .addthis_inline_share_toolbox {
            width: 46px;
            margin-left: auto;
            margin-right: auto;
            .at-share-btn {
                margin-top: 15px;
            }
        }
    }
}
