
.vlimg {
    position: relative;
    .ico {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: #2b9646;
        color: #fff;
    }
    img {
        width: 100%;
    }
}
a:hover {
    .vlimg {
        .ico {
            background: lighten(#2b9646, 10%);
        }
    }
}

.vlog-item {
    .uk-card-body {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.uk-slidenav {
    background: #ccd2e6;
}
