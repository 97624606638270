
#newsletter-subscribe-bottom {

    .uk-card-body {
        position: relative;
        .loading, .result {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #fff;
        }

        .loading {
          //background: url('/assets/images/newsletter/preloader32px.gif') scroll no-repeat center center #fff;
        }

        .result {
          z-index: 999;
          &> div {
            //height: 100%;
            //width: 100%;
          }
          strong {
            display: block;
            small {
              display: block;
            }
          }
        }

        .loading {
          z-index: 1000;
        }
    }

    h2 {
        margin-bottom: 20px;
    }
    .uk-light {
        p {
            margin-bottom: 0;
        }
    }
    p.uk-margin {
        margin-top: 20px !important;
    }
    .uk-card-body {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .uk-button-secondary {
        padding-left: 0;
        font-style: italic;
        border: 0;
        img {
            padding-right: 30px;
        }
    }
}
