.home-header-search {
    .uk-text-center {
        line-height: 48px;
        li {
            min-height: 48px;
        }
        a {
            display: block;
            font-size: 15px;
            font-weight: 500;
            font-style: italic;
            color: #2b9646;
            line-height: 21px;
        }
    }

    .uk-grid-margin {
        margin-top: 0;
    }


}

#global-search-form {
    //background: #f6f6f6;
    width: 200px;
    .uk-search-icon {
        color: #2b9646;
    }
    .algolia-autocomplete,
    input {
        height: 48px;
        width: 100%;
    }
    .uk-search-input {
        padding-left: 16px;
        padding-right: 40px;
    }

    .aa-dropdown-menu {
        left: auto !important;
        right: 0 !important;
    }
}

.home-header {
    position: relative;
    .uk-icon-button {
        background: #fff;
    }
    .w-1-1 {
        h1 {
            margin-bottom: 20px;
        }
        * + .uk-margin {
            margin-top: 20px !important;
        }
        .uk-margin {
            margin-bottom: 20px !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
    .left {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        transform: rotate(90deg);
        z-index: 2;
        &> div {
            width: 80vh;
            text-align: center;
        }
        .uk-grid {
            &> * {
                a {
                    display: block;
                    line-height: 50px;
                    color: #fff;
                    text-decoration: none !important;
                    &:hover {
                        color: #fff;
                    }
                }
                &:first-child {
                    a {
                        background: #2b9646;
                        &:hover {
                            background: lighten(#2b9646, 10%);
                        }
                    }
                }
                &:last-child {
                    a {
                        background: #227a39;
                        &:hover {
                            background: lighten(#227a39, 10%);
                        }
                    }
                }
            }
        }
    }
}
