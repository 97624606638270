.search-index {
    .ais-InfiniteHits-list {
        &> li {
            margin-top: 30px;
            &:first-child {
                margin-top: 0;
            }
            &> p {
                margin-bottom: 0;
            }
        }
    }
    .ais-RefinementList-label {
        white-space: normal;
    }

    #currentRefinements {
        margin-top: 10px;
    }

    .filters {
        margin-top: -16px;
    }
}
