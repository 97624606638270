@keyframes document-download-blinker {
  50% {
    opacity: 0;
  }
}

a.document-download {
    transition: all 0.1s ease-in-out;
    &:hover {
      transition: all 0.1s ease-in-out;
      .type-icon {
        color: $text-meta-link-color;
      }
    }
    .d {
      display: none;
    }
    .download-icon {

    }
    &.downloading {
      .d {
        display: block;
      }
      .n {
        display: none;
      }
      .download-text {
        animation: document-download-blinker 1s linear infinite;
      }
    }
  }
