.sf-toolbar {
  // display: none !important;
}

#newsletter-subscribe-bottom {
  //display: none !important;
}

.m100vh {
  min-height: 100vh;
}
.m80vh {
  min-height: 80vh;
}
.m50vh {
  min-height: 50vh;
}
.m40vh {
  min-height: 40vh;
}
.m30vh {
  min-height: 30vh;
}
.m25vh {
  min-height: 25vh;
}
.m20vh {
  min-height: 20vh;
}

.w-1-1 {
  width: 100%;
  box-sizing: border-box;
}

.uk-width-1-6 {
  width: 100% / 6 * 1;
}

.uk-width-2-6 {
  width: 100% / 6 * 2;
}

.uk-width-3-6 {
  width: 100% / 6 * 3;
}
.uk-width-4-6 {
  width: 100% / 6 * 4;
}
.uk-width-5-6 {
  width: 100% / 6 * 5;
}


a.uk-card {
  display: block;
}

a.uk-card-default {
  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.28);
  }
}

.bg-b-g {
  background: rgba(0,0,0, .4);
  background: linear-gradient( rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
}

.img-copyright {
  text-align: right;
  font-size: 12px;
}

.tm-content {
  position: relative;
}
.tm-content-fix {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 100;
  width: 70px !important;

  .addthis_inline_follow_toolbox {
    margin-top: 100px;
    padding: 10px 10px;
    text-align: center;
    background: #fff;
    box-shadow: 0 1px 18px rgba(137, 137, 137, 0.07);
    background-color: #ffffff;

    .addthis_toolbox {
      width: 40px;
      margin-left: auto;
      margin-right: auto;
    }

  }

}

.bmrb {
  position: absolute;
  bottom: -19px;
  right: 0;
  z-index: 100;
}

a.img,
a .img {
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    background: #000;
  }
}
a.img:hover:before,
a:hover .img:before {
  opacity: .2;
}


.uk-navbar-nav > li > a[href='#'] { cursor: pointer; }

.uk-navbar-container,
.uk-container-small {
  padding-left: 20px;
  padding-right: 20px;
}

.pagination {
  margin-top: 50px;
}

.logo {
  color: #000000;
}


$sidebar-width: 280px;
.tm-sidebar-left {
  display: none;
}
.tm-main {
  padding-bottom: 150px;
}
@media (min-width: $breakpoint-large) {
  .tm-sidebar-left {
    display: block;
    position: fixed;
    top: $navbar-nav-item-height;
    bottom: 0;
    box-sizing: border-box;
    width: $sidebar-width !important;
    padding-left: 40px;
    padding-right: 40px;
    border-right: 1px #e5e5e5 solid;
    overflow: auto;
  }
  .tm-main {
    margin-left: $sidebar-width;
  }
}

.uk-search-large {
  background: #f8f8f8;
}

#user-switcher {
  .uk-search,
  .uk-search-large {
    background: transparent;
    font-size: $search-large-font-size;
    .uk-search-input {
      height: 40px;
    }
  }
}

#tm-fixed {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  a {
    background: #0E324D;
    color: #fff;
    border-radius: 50%;
    display: block;
    padding: 15px;
    &:hover {
      background: darken(#0E324D, 10%);
    }
  }
}

.side-info {
  h6 {
    color: #2b9646;
    font-weight: 300;
    font-style: italic;
  }

  .uk-text-bold {
    font-weight: 400;
  }
}

.tm-footer-container {
  font-size: 14px;
  .social {
    width: 80px;
    text-align: center;
  }
  line-height: 26px;
}

.user-list > div,
.user-list > li {
  a,
  a.uk-card {
    padding: 10px;
    /*
    &.agent {
      color: green !important;
    }
    &.client {
      color: blue !important;
    }
    */
    &:hover {
      background: #f8f8f8;
    }
  }
}

.user-list {

}

.uk-modal-header {
  border-bottom: 0;
}

.uk-search-large {
  .uk-search-icon {
    &> svg {
      width: 30px;
    }
  }
  .uk-search-input {
    padding-left: 20px;
  }
}



#user-search {

}
