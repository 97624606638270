@import url('https://fonts.googleapis.com/css?family=Fira+Sans:100,100i,200,200i,400,400i,500,500i,600,600i,900,900i');



$primary-background: #707070;
$primary-hover-background: lighten($primary-background, 10%);
$primary-color: #fff;
$primary-hover-color: $primary-color;
$offcanvas-bar-background: #2b9646;
$offcanvas-color: #fff;
$inverse-nav-default-item-color: #fff;


$margin-margin: 40px;

$base-body-background:                           #fff;
$base-body-font-family:                          'Fira Sans', 'Open Sans', sans-serif;
$base-body-font-weight:                          300;
$base-body-font-size:                            16px;
$base-body-line-height:                          26px;
$base-body-color:                                #000;
$base-em-color:                                  inherit;
$base-ins-background:                            #ffa;
$base-ins-color:                                 #444;
$base-mark-background:                           #ffa;
$base-mark-color:                                #444;
$base-quote-font-style:                          italic;
$base-margin-vertical:                           15px;
$base-heading-font-family:                       'Fira Sans', 'Open Sans', sans-serif;
$base-heading-font-weight:                       500;
$base-heading-color:                             #000;
$base-heading-text-transform:                    none;
$base-heading-margin-top:                        25px;
$base-h1-font-size:                              36px;
$base-h1-line-height:                            42px;
$base-h2-font-size:                              26px;
$base-h2-line-height:                            32px;
$base-h3-font-size:                              18px;
$base-h3-line-height:                            24px;
$base-h4-font-size:                              18px;
$base-h4-line-height:                            24px;
$base-h5-font-size:                              14px;
$base-h5-line-height:                            20px;
$base-h6-font-size:                              14px;
$base-h6-line-height:                            18px;
$text-bold-font-weight: 500;

$section-primary-background: #f8f9fd;
$card-primary-background: $section-primary-background;
$card-primary-color: $base-body-color;
$overlay-default-background: #379c51;
$tile-primary-background: #379c51;
$text-meta-link-color: #2b9646;

$base-link-color:                                inherit;
$base-link-text-decoration:                      none;
$base-link-hover-color:                          $text-meta-link-color;
$base-link-hover-text-decoration:                none;


$tab-item-color: #fff;
$tab-item-active-color: $tab-item-color;
$tab-item-hover-color: $tab-item-color;
$tab-item-padding-vertical: 28px;
$tab-item-padding-horizontal: 45px;
$tab-margin-horizontal: 0;

$form-label-color: inherit;
$form-label-font-size: 18;


$border-rounded-border-radius: 15px;
$navbar-nav-item-height: 60px;
$navbar-background: #fff;
$navbar-nav-item-color: #000000;
$navbar-nav-item-hover-color: lighten(#000000, 10%);
$navbar-nav-item-text-transform: none;

$button-default-background: #2b9646;
$button-default-color: #fff;
$button-default-hover-background: lighten(#2b9646, 10%);
$button-default-hover-color: #fff;
$button-default-border: none;

$button-primary-background: #ffb52f;
$button-primary-color: #fff;
$button-primary-hover-background: lighten(#ffb52f, 10%);
$button-primary-hover-color: #fff;
$button-primary-border: none;




$button-link-color: #646464;
$button-secondary-background: #113a89;
$button-secondary-color: #fff;
$button-secondary-hover-background: lighten(#113a89, 10%);
$button-secondary-hover-color: #fff;

$badge-size:                                     34px;
$badge-padding-vertical:                         0;
$badge-padding-horizontal:                       15px;
$badge-border-radius:                            0;
$badge-background:                               #2b9646;
$badge-color:                                    #fff;
$badge-font-size:                                16px;

$badge-hover-color:                              #fff;



$section-large-padding-vertical: 50px;

$nav-item-padding-vertical: 10px;

$card-title-font-size: 1.2rem;
$search-large-font-size: 1.2rem;
$text-meta-font-size: 0.7rem;

$search-default-padding-horizontal: 16px;

//$search-large-icon-width: 60px;
//$search-large-height: 60px;

// card title 1.4rem
// documents suche 1.5rem
// search padding-right
