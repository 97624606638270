.publication-index {
    .header {
        color: #fff;
        h1, p {
            color: #fff;
        }

        .ais-SearchBox,
        .ais-SearchBox-form {
            width: 100%;
        }
    }
    .filters {
        background: #2b9646;
        line-height: $button-large-line-height;
        &> div {
            &> div {
                display: block;
                @media (min-width: 1200px) {
                    display: flex;
                }

            }
        }
        b {
            font-weight: 500;
            color: #fff;
            padding: 0 40px;
            @media (min-width: 1200px) {
                padding: 0;
            }

        }
    }

    .ais-InfiniteHits-item {
        margin-bottom: 90px;
    }
    .ais-CurrentRefinements-label {
        display: none;
    }

    .ais-CurrentRefinements-item {
        background: #f2f6fe;
        color: #2b9646;
        padding: 15px 30px;
    }
    .ais-CurrentRefinements-categoryLabel,
    .ais-CurrentRefinements-delete {
        color: #2b9646;
        font-size: 18px;
    }

    .ais-CurrentRefinements-categoryLabel {
        font-weight: 500;
    }

    .ais-CurrentRefinements-list {
        margin-bottom: 70px;
    }
    .ais-CurrentRefinements-list:empty {
        margin-bottom: 0;
    }
}


.publication-show {
    .side-info .img {
        @media(min-width: 1200px) {
            margin-top: 0 - $section-padding-vertical - 120px;
        }
    }
    .btn-d {
        margin-top: 0 - ($button-line-height / 2);
        text-transform: none;
    }
}
