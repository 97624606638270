
.uk-form-label {
    font-weight: 500;
    font-style: italic;
    margin-bottom: 20px;
    display: block;
}

/*
.uk-button-link {
    padding: 0 $button-padding-horizontal;
}
*/
