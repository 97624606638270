#youtube-modal {
    .uk-modal-dialog {
      width: 900px;
    }
    .content {
      padding: 25px 30px;
    }
    .title {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 20px;
      a {
        color: inherit;
      }
    }
    .info-meta {
      &:before {
        content: "";
        display: table-cell;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }


    .channel {
      float: left;
      max-width: 50%;
      .channel-logo {
        float: left;
        height: 48px;
        margin-right: 14px;
        width: 48px;
      }
      .channel-info {
        float: left;
        line-height: 1;
      }
      .channel-name {
        color: #222;
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        top: -1px;
        white-space: nowrap;
      }
      .channel-subscribe {
        margin-top: 9px;
      }
    }
    .properties {
      float: right;
      max-width: 50%;
      min-width: 110px;
      text-align: right;
    }
    .views {
      color: rgb(85, 85, 85);
      font-size: 21px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .rating {
      .rating-ratio {
        background: rgb(207, 207, 207);
        height: 2px;
        margin: 5px 0;
        span {
          background: rgb(47, 165, 255);
          display: block;
          height: 100%;
        }
      }
      .rating-counters-likes,
      .rating-counters-dislikes {
        display: inline-block;
        span {
          color: rgb(144, 144, 144);
          font-size: 12px;
          font-weight: 500;
        }
      }
      .rating-counters-likes {

      }

      .rating-counters-dislikes {
        margin-left: 25px;
      }
    }

    .info-main {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      color: #222;
      font-size: 13px;
      line-height: 18px;
      margin-top: 10px;
      padding-top: 12px;
    }
    .date {
      color: #222;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 3px;
    }
    .description {
      color: rgb(34, 34, 34);
    }
  }
