
@mixin hook-tab(){
    position: relative;
}


@mixin hook-tab-item() {
    background: #2b9646;
    border: 0;
}

@mixin hook-tab-item-hover() {
    background: #227a39;
}
@mixin hook-tab-item-active(){
    background: #227a39;

}

