.page-home {
    .stories {

        .main {
            margin-top: 40px;
            @media(min-width: 1200px) {
                margin-top: -260px;
            }
            &> div {
                width: 100%;
                @media(min-width: 1200px) {
                    width: 3 * 270px + 20px;
                    max-width: 60%;
                }
                a {
                    position: relative;
                    z-index: 2;
                    img {
                        width: 100%;
                    }
                    @media(min-width: 1200px) {
                        img {
                            width: auto;
                        }
                    }
                    &:hover {
                        box-shadow: 0 5px 15px rgb(0 0 0 / 28%);
                        &::before {
                            //opacity: 0;
                        }
                        /*
                        .uk-overlay {
                            background: transparent;
                        }
                        */
                        h3 {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.page-story-index {
    .first {
        margin-top: 40px;
        @media(min-width: 1200px) {
            margin-top: -220px;
        }
        img {
            width: 100%;
        }
        h3 {
            font-size: 28px;
        }
    }
}

.page-story-show,
.story-item {
    .author {
        color: $text-meta-link-color;
    }
}
.page-story-show {
    .people-item {
        .uk-text-center {
            text-align: left !important;
        }
        h3.name {
            font-size: 14px;
            margin-bottom: 5px;
        }
        .uk-text-meta {
            margin-top: 0;
            margin-bottom: 0;
        }

    }
}

.story-show .desc {
    img {
        height: auto !important;
    }
}

.page-event-show .side-info {
    .uk-card-body {
        .uk-button-primary {
            background-color: $button-primary-background;
            color: $button-primary-color;
            &:hover {
                background-color: $button-primary-hover-background;
                color: $button-primary-hover-color;
            }
        }
    }

}

.uk-card-primary.uk-card-body {
    color: inherit !important;
    h3,
    p,
    a:not(.uk-button) {
        color: inherit !important;
    }
    h6 {
        color: $text-meta-link-color;
    }

}
.page-story-show {
    .topic {
        color: $text-meta-link-color;
        font-size: 18px;
        font-weight: 400;
    }
}

.page-people-index {
    .header {
        padding-bottom: 155px;
        img {
            margin-bottom: -155px;
        }
    }
}

.people-item {
    .name {
        font-weight: 500;
    }
    .position {
        font-size: 14px;
        font-weight: 300;
    }
}

.event-item {
    .day {
        font-size: 3rem;
    }
}


.subpage {
    .subnav {
        margin-top: -41px;
        .uk-button {
            line-height: 82px;
        }
    }
    .uk-tab > * > a {
        padding-left: 10px;
        padding-right: 10px;
    }
    .side-img {
        margin-top: 40px;
        @media(min-width: 1200px) {
            margin-top: -200px;
        }
    }

    .side-img-c {
        height: 123px;
        background-color: #379c51;
        color: #fff;
        padding: 34px 120px 34px 20px;

        background-repeat: no-repeat;
        background-position: top right;
    }
}
