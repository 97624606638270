.tm-header-overlay {
  position:absolute;
  z-index:980;
  left:0;
  right:0;
}

.tm-header-overlay {

}

.uk-navbar-nav > li.uk-active > a,
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li > a.uk-open {
  text-shadow: 1px 0 0 black;
}

.tm-header {

  .uk-logo {
    padding: 0;
  }
  .logo {
    width: 230px;
    img, svg {
      width: auto;
    }
  }
  .uk-navbar-container {
    &> .uk-grid + .uk-grid {
      margin-top: 0;
    }
  }
}
.tm-header-mobile {
  .logo {
    width: 80px;
  }
  .uk-navbar-container:last-child {
    background: #fff;
    color: #999;
    border-bottom: 1px solid #e5e5e5;
    padding-right: 0;
    .uk-navbar-toggle {
      border-left: 1px solid #e5e5e5;
    }
    .uk-navbar-nav > li > a,
    .uk-navbar-item,
    .uk-navbar-toggle {
      color: #999;
    }
    .uk-navbar-nav > li:hover > a,
    .uk-navbar-nav > li > a:focus,
    .uk-navbar-nav > li > a.uk-open {
      color: #e6e6e6;
    }
  }
}

.uk-search-navbar .uk-search-input {
  height: $navbar-nav-item-height;
  /*
  &:hover {
    background: #fff;
  }
  */
}


#animation_container {
  canvas {
    max-width: initial;
    max-width: none;
    height: initial;
    box-sizing: initial;
    all: initial;
  }
}
